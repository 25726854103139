import * as React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../components/layout/Layout';

const NotFoundPage = ({ data }) => {
  
  const {t} = useTranslation();

  return (
    <Layout 
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
    >
      <div className="row align-items-center my-7">
        <div className="col-12 col-md-6 offset-xl-1 offset-md-1 order-md-2 mb-5 mb-md-0">
          <div className="text-center">
            <StaticImage imgClassName="img-fluid" quality="50" src="../images/illustrations/thibault-crouzet-404-not-found.svg" alt={data.site.siteMetadata.title} />
          </div>
        </div>
        <div className="col-12 col-md-5 col-xl-5 order-md-1 my-5">
          <div className="text-center">
            <h6 className="text-uppercase text-muted mb-4">{t('pretitle')}</h6>
            <h1 className="display-4 mb-3">{t('title')}</h1>
            <p >{t('subtitle')}</p>
            <Link to={`/`} className="btn btn-lg btn-primary">{t('backHome')}</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        title
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "404", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

